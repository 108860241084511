const fontSans = "'LL Brown', 'Arial', sans-serif"
const fontSerif = "'ff-meta-serif-web-pro', 'Georgia', serif"

const typography = ({theme}) => ({
    ...theme.typography,
    h1: {
        fontFamily: fontSans,
        fontWeight: 100,
        fontSize: 32,
        lineHeight: 1.1,
        marginBottom: "0.5em",

        [theme.breakpoints.up('md')]: {
            fontSize: 50,
        }        
        
    },
    h2: {
        fontFamily: fontSans,
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.25,
        marginTop: '.5em',

        [theme.breakpoints.up('md')]: {
            fontSize: 30,
        },        


    },
    h3: {
        fontFamily: fontSans,
        fontSize: 17,
        fontWeight: "bold",
        lineHeight: 1.25,
        marginTop: '.5em',

        [theme.breakpoints.up('md')]: {
            fontSize: 21,
        },

        "& + p": {
            marginTop: "0.25em"
        },

    },
    h4: {
        fontFamily: fontSans,
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 'bold',
        lineHeight: 1.5,
    },
    h5: {
        fontFamily: fontSans,
        fontSize: 16,
        fontStyle: "normal",
        fontWeight: 'normal',
        lineHeight: 1.5,
    },
    h6: {
    },
    /*
    subtitle1: {
        fontFamily: fontSans,
        fontSize: 20,
        fontWeight: "bold",
        lineHeight: 1.25,
    },
    subtitle2: {
        fontFamily: fontSans,
        fontSize: 17,
        fontWeight: "bold",
        lineHeight: 1.25,
        marginTop: '1.5em',
    },
    */
    body1: {
        fontFamily: fontSerif,
        fontSize: 16,
        fontWeight: 100,
        lineHeight: 1.5,
        margin: '1em 0 1.5em',

        "& + $body1": {
            marginTop: ".5em"
        },

        [theme.breakpoints.up('md')]: {
            fontSize: 20,
        }

    },
    body2: {
        fontFamily: fontSans,
        fontSize: 15,
        fontWeight: 'normal',
        lineHeight: 1.5,
        margin: '1em 0 1.5em',

        "& + $body1": {
            marginTop: ".5em"
        },

        [theme.breakpoints.up('md')]: {
            fontSize: 17,
        }

    },
    button: {

    },
    caption: {

    },
    overline: {
        fontFamily: fontSans,
        fontSize: 15,
        fontWeight: 'bold',
        lineHeight: 1.5,
        textTransform: "none",
        margin: '.5em 0 .5em',
       
    },
})

export default typography