import React from 'react';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import typography from "./typography"
import '../../styles/layout.css';

const theme = createMuiTheme({
    typography: {
        fontFamily: ['"LL Brown", sans-serif'].join(),
    }
});

theme.typography = typography({theme})

const Theme = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            {children}
        </ThemeProvider>
    );
}

export default Theme