import React from 'react'
import Link from 'gatsby-plugin-transition-link'
import Logo from "../Logo/Logo"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    header: {
        position: "relative",
        zIndex: 2,
        width: "100%",
        
        paddingTop: 44,
        paddingBottom: 33,
    
        [theme.breakpoints.up('md')]: {
            paddingTop: 56,
            paddingBottom: 42
        },
    
        '& @include media("print")': {
            paddingTop: '0.5cm',
            paddingBottom: '0.5cm'
        },

        display: "flex",
        justifyContent: "center",
        alignItems: "center"
    
    },
    logo: {
        display: "block",
        width: 210,
        height: 40,
        margin: 0,
 
        '& @include media("print")': {
            width: "32mm",
        },
    }
}));


const Header = (props) => {

    const classes = useStyles()

    return (
        <header className={classes.header}>
            <Link to={"/"}><Logo className={classes.logo} /></Link>
        </header>
    )

}


Header.defaultProps = {
    title: '',
    nodes: [],
    browseMoreHref: ''
}

export default Header
