import React from 'react'
import Link from 'gatsby-plugin-transition-link'
import Typography from "@material-ui/core/Typography"
import ArticleLink from "../Article/ArticleLink"

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    person: {
        fontStyle: "normal",
        margin: 0,

        "& a": {
            textDecoration: "none",
            color: "inherit"
        }

    },
    label: {
        display: "none"
    }
}));

const ContactPerson = ({
    url,
    name,
    phone,
    email
}) => {

    const phoneLink = "tel:" + phone
    const emailLink = "mailto:" + email

    const classes = useStyles()

    return (
        <Typography className={classes.person} component="address" variant="body2">
            { url && <strong><Link to={url}>{name}</Link></strong> }
            { phone && <div><strong className={classes.label}>Telefon:</strong> <ArticleLink href={phoneLink}>{phone}</ArticleLink></div> }
            { email && <div><strong className={classes.label}>E-post:</strong> <a href={emailLink}>{email}</a></div> }
        </Typography>
    )

}


ContactPerson.defaultProps = {
    url: '',
    name: '',
    phone: '',
    email: ''
}

export default ContactPerson
