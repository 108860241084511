import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon"

const Logo = (props) => {

    return (
        <SvgIcon {...props}viewBox="0 0 210 40">
            <path d="M21.1 12.8C20.8 12.7 20.4 12.7 20.1 12.7C19.3 12.7 18.6 12.9 18.2 13.4C17.9 13.8 17.7 14.2 17.7 15.4V15.8H21.6V18.7H17.7V28.5H14.3V18.7H11V15.8H14.3V15.3C14.3 13.3 14.8 12 15.7 11.2C16.6 10.3 17.9 9.70001 19.6 9.70001C20.3 9.70001 20.9 9.80001 21.6 9.90001L21.1 12.8Z" fill="black"/>
            <path d="M29.6 15.5C33.4 15.5 36.3 18.4 36.3 22.1C36.3 25.8 33.4 28.7 29.6 28.7C25.8 28.7 22.9 25.8 22.9 22.1C22.9 18.5 25.8 15.5 29.6 15.5ZM29.6 25.6C31.6 25.6 32.9 24.1 32.9 22.2C32.9 20.3 31.5 18.7 29.6 18.7C27.6 18.7 26.3 20.3 26.3 22.2C26.3 24.1 27.6 25.6 29.6 25.6Z" fill="black"/>
            <path d="M39.6 24.6C40.5 25.4 41.8 25.9 43.1 25.9C44 25.9 44.7 25.5 44.7 24.8C44.7 22.9 38.6 23.6 38.6 19.5C38.6 17.1 40.9 15.6 43.5 15.6C45.1 15.6 46.9 16.2 47.8 16.9L46.2 19.4C45.5 18.9 44.7 18.5 43.8 18.5C42.9 18.5 42.1 18.9 42.1 19.5C42.1 21.1 48.2 20.5 48.2 24.9C48.2 27.3 45.8 28.8 43.2 28.8C41.5 28.8 39.6 28.2 38.1 27.1L39.6 24.6Z" fill="black"/>
            <path d="M51.5 24.6C52.4 25.4 53.7 25.9 55 25.9C55.9 25.9 56.6 25.5 56.6 24.8C56.6 22.9 50.5 23.6 50.5 19.5C50.5 17.1 52.8 15.6 55.4 15.6C57 15.6 58.8 16.2 59.7 16.9L58 19.3C57.3 18.8 56.5 18.4 55.6 18.4C54.7 18.4 53.9 18.8 53.9 19.4C53.9 21 60 20.4 60 24.8C60 27.2 57.6 28.7 55 28.7C53.2 28.7 51.4 28.1 49.9 27L51.5 24.6Z" fill="black"/>
            <path d="M66.5 28.5H63.1V9.90002H66.5V28.5Z" fill="black"/>
            <path d="M82.6 28.5H79.3V27.5C78.3 28.4 77.1 28.8 75.8 28.8C73.9 28.8 72.1 28 71 26.7C70 25.6 69.4 24 69.4 22.3C69.4 18.6 72.2 15.7 75.6 15.7C77 15.7 78.3 16.2 79.3 17V16H82.6V28.5V28.5ZM79.2 20.2C78.5 19.3 77.4 18.6 76.1 18.6C74.1 18.6 72.8 20.2 72.8 22.1C72.8 24.1 74.2 25.5 76.1 25.5C77.4 25.5 78.5 24.9 79.2 23.9V20.2Z" fill="black"/>
            <path d="M86.5 28.5V15.8H89.8V16.8C90.8 16 92 15.5 93.4 15.5C95.1 15.5 96.7 16.3 97.8 17.5C98.6 18.5 99.1 19.8 99.1 21.9V28.3H95.7V21.6C95.7 20.3 95.4 19.7 94.9 19.2C94.5 18.8 93.8 18.5 93 18.5C91.6 18.5 90.4 19.4 89.9 20.1V28.3H86.5V28.5Z" fill="black"/>
            <path d="M111.5 28.5V27.5C110.5 28.4 109.3 28.8 108 28.8C106.1 28.8 104.3 28 103.2 26.7C102.2 25.6 101.6 24 101.6 22.3C101.6 18.6 104.4 15.7 107.8 15.7C109.2 15.7 110.4 16.1 111.4 16.9V9.90002H114.8V28.4H111.5V28.5ZM111.4 20.2C110.7 19.3 109.6 18.6 108.3 18.6C106.3 18.6 105 20.2 105 22.1C105 24.1 106.4 25.5 108.3 25.5C109.6 25.5 110.7 24.9 111.4 23.9V20.2Z" fill="black"/>
            <path d="M129.5 19.7V15.1H132.4V19.7H137V22.6H132.4V27.2H129.5V22.6H124.9V19.7H129.5V19.7Z" fill="black"/>
            <path d="M155.7 12.8C155.4 12.7 155 12.7 154.7 12.7C153.9 12.7 153.2 12.9 152.8 13.4C152.5 13.8 152.3 14.2 152.3 15.4V15.8H156.2V18.7H152.3V28.5H149V18.7H145.7V15.8H149V15.3C149 13.3 149.5 12 150.4 11.2C151.3 10.3 152.6 9.70001 154.3 9.70001C155 9.70001 155.6 9.80001 156.3 9.90001L155.7 12.8Z" fill="black"/>
            <path d="M164.3 15.5C168.1 15.5 171 18.4 171 22.1C171 25.8 168.1 28.7 164.3 28.7C160.5 28.7 157.6 25.8 157.6 22.1C157.6 18.5 160.5 15.5 164.3 15.5ZM164.3 25.6C166.3 25.6 167.6 24.1 167.6 22.2C167.6 20.3 166.2 18.7 164.3 18.7C162.3 18.7 161 20.3 161 22.2C161 24.1 162.3 25.6 164.3 25.6Z" fill="black"/>
            <path d="M174.3 24.6C175.2 25.4 176.5 25.9 177.8 25.9C178.7 25.9 179.4 25.5 179.4 24.8C179.4 22.9 173.3 23.6 173.3 19.5C173.3 17.1 175.6 15.6 178.2 15.6C179.8 15.6 181.6 16.2 182.5 16.9L180.9 19.4C180.2 18.9 179.4 18.5 178.5 18.5C177.6 18.5 176.8 18.9 176.8 19.5C176.8 21.1 182.9 20.5 182.9 24.9C182.9 27.3 180.5 28.8 177.9 28.8C176.2 28.8 174.3 28.2 172.8 27.1L174.3 24.6Z" fill="black"/>
            <path d="M186.2 24.6C187.1 25.4 188.4 25.9 189.7 25.9C190.6 25.9 191.3 25.5 191.3 24.8C191.3 22.9 185.2 23.6 185.2 19.5C185.2 17.1 187.6 15.6 190.1 15.6C191.7 15.6 193.5 16.2 194.4 16.9L192.8 19.4C192.1 18.9 191.3 18.5 190.4 18.5C189.5 18.5 188.7 18.9 188.7 19.5C188.7 21.1 194.8 20.5 194.8 24.9C194.8 27.3 192.4 28.8 189.8 28.8C188.1 28.8 186.2 28.2 184.7 27.1L186.2 24.6Z" fill="black"/>
            <path d="M206.9 3.1V36.8H3.1V3.1H206.9ZM210 0H0V40H210V0Z" fill="black"/>
        </SvgIcon>
    )

}


export default Logo