import React from 'react'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    grid: {
        width: '100%',
        maxWidth: 280,
        margin: '0 auto',
    
        [theme.breakpoints.up('md')]: {
            maxWidth: 520
        }
 
    }
}));

const ArticleGrid = ({children}) => {

    const classes = useStyles()

    return (
        <div className={classes.grid}>
            {children}
        </div>
    )

}


ArticleGrid.defaultProps = {
}

export default ArticleGrid
