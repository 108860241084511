import React from 'react'
import Link from 'gatsby-plugin-transition-link'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    link: {
        color: "inherit"
    },
    
}));


const ArticleLink = ({children, variant = "mobile", href, ...props}) => {

    const classes = useStyles()
    
    if (!href.includes('.') && !href.includes(':')) {
        return (
            <Link className={classes.link} to={href}>{children}</Link>
        )
    }

    if (href.includes('tel:')) {

        let string = href.replace('tel:', '')

        let prefix

        if (string.includes("+")) {

            const split = string.split(' ')

            prefix = split[0]

            string = string.replace(prefix, '')

            /*

            return (
                <a className={classes.link} href={href}>{children}</a>
            )
            */
        }
        
        let cleaned = ('' + string).replace(/\D/g, '')
        let match = cleaned.match(/^(\d{3})(\d{2})(\d{3})$/)

        let number;

        if (match) {
            number = match[1] + ' ' + match[2] + ' ' + match[3]
        }

        if (prefix) {
            number = prefix + ' ' + number;
        }

        href = "tel:"+number

        return (
            <a className={classes.link} href={href}>{number}</a>
        )

        return null

    }

    return (
        <a className={classes.link} href={href}>{children}</a>
    )

    return (
        <p>
            {children}
            {JSON.stringify(props)}
        </p>
    )
    

}

export default ArticleLink

